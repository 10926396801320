import { ClassNames } from '@emotion/react';
import { useTheme, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { t } from 'i18next';
import { logo1, mail, ph, f1, f2, f3 } from 'src/Assets';
const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    height: 100,
    background: theme.Colors.primary,
    color: theme.Colors.white
  }
}));

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <footer>
      <div className="f1">
        <img src={logo1} alt="" />
        <p>{t('WEBFOOTER.eWasteFooter')}</p>
        <div className="symbols">
          <a href="https://www.facebook.com/">
            <img className="sym" src={f1} alt="" />
          </a>
          <a href="https://www.instagram.com/accounts/login/">
            <img className="sysm" src={f2} alt="" />
          </a>
          <a href="https://www.linkedin.com/">
            <img className="sym" src={f3} alt="" />
          </a>
        </div>
      </div>

      <div className="f2">
        <h5>{t('WEBFOOTER.workingHours')}</h5>
        <p>{t('WEBFOOTER.everyDay')}</p>
        <div className="f2-time">
          <p>{t('WEBFOOTER.mondayTo')}</p>
          <p>10:00 - 18:30</p>

          <p>{t('WEBFOOTER.sundayTo')}</p>
          <p>10:00 - 13:00</p>
        </div>
      </div>

      <div className="f3">
        <h5>{t('WEBFOOTER.workEnquires')}</h5>
        <p>
          {t('WEBFOOTER.interested')}
          <br />
          <div className="contact">
          <a href="mailto: support@dustmanrecycling.com">
            <img src={mail} alt="" />
          </a>
          <p>support@dustmanrecycling.com</p>
          </div>
        </p>
      </div>

      <div className="f4">
        <h5>{t('WEBFOOTER.stayInTouch')}</h5>
        <div className="contact">
          <a href="mailto: info@dustmanrecycling.com">
            <img src={mail} alt="" />
          </a>
          <p>info@dustmanrecycling.com</p>
          <a href="callto: +918939877444">
            <img src={ph} alt="" />
          </a>
          <p>+91 8939909555, +918939976555</p>
        </div>
      </div>

      <div className="copyright">
        <p>Privacy & Cookie Policy | Terms of Service</p>
        <p>Copyright © 2022, Designed & Developed by Unitive Technologies</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import AddressDisplay from './AddressDisplay';
import {
  ButtonComp,
  EnquiryComp,
  UHConfirmModal,
  UHTabComponent
} from 'src/components';
import BookYourPickup from 'src/dustmanUI/content/BookYourPickup';
import Profile from 'src/dustmanUI/content/Profile';
import OrdersPages from 'src/dustmanUI/content/OrdersPage';
import {
  BookYourPageImage,
  RoundPattern,
  DottedPattern
} from 'src/Assets/Images';
import { CONFIRM_MODAL, HTTP_STATUSES, ORIENTATION } from 'src/Config/constant';
import { t } from 'i18next';
import { API_SERVICES } from 'src/dustmanUI/Services';
import toast from 'react-hot-toast';
//import useUserInfo from 'src/hooks/useUserInfo';
import useUserInfo from 'src/hooks/useUserInfo';

const useStyles = makeStyles((theme: Theme) => ({
  superOuterContainer: {
    background: '#e2e1e1',
    // 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(14.66deg, #70B245 -27.51%, #FFFFFF 54.57%)',
    width: '100%',
    padding: theme.spacing(3, 12, 12, 16)
  },
  imageContainer: {
    paddingLeft: theme.spacing(8),
    position: 'sticky',
    top: 0,
    height: '100%'
  },
  addressContainer: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  tabContainer: {
    backgroundColor: 'transparent',
    width: '100%'
  },
  tabRoot: {
    fontSize: theme.MetricsSizes.small_xxx,
    color: theme.Colors.mediumBlack,
    fontWeight: theme.fontWeight.regular
  },
  selectedTab: {
    fontWeight: theme.fontWeight.bold,
    color: theme.Colors.blueMediumDark
  },
  buttonContainer1: {
    borderTopRightRadius: '40px',
    borderTopLeftRadius: '80px',
    borderBottomLeftRadius: '55px',
    boxShadow: '2px 4px 0px #888888'
  },
  textStyle: {
    paddingLeft: '42px',
    fontWeight: theme.fontWeight.bold,
    fontSize: theme.MetricsSizes.small_xxx
  }
}));

function HomePage() {
  const classes = useStyles();
  const [confirmModal, setConfirmModal] = useState<any>({ open: false });
  const theme = useTheme();
  const tabItems = [
    {
      id: 1,
      label: `${t('CUSTOMERHOME.bookYourPickup')}`,
      component: () => <BookYourPickup onTabChange={onTabChange} />
    },
    {
      id: 2,
      label: `${t('CUSTOMERHOME.orders')}`,
      component: () => <OrdersPages />
    },
    {
      id: 3,
      label: `${t('CUSTOMERHOME.profile')}`,
      component: () => <Profile />
    }
  ];
  const [selectedTab, setSelectedTab] = useState(tabItems[0]?.id);
  const { userDetails } = useUserInfo();

  const onTabChange = (value: any) => {
    setSelectedTab(value);
  };

  const onCancelClick = () => {
    setConfirmModal(false);
  };

  let confirmModalProps = {
    color: theme.Colors.primary,
    // description: t('customerOrder.cancelCustomerOrder'),
    // title: t('customerOrder.cancel'),
    description: `${t('pleaseWaitDustmanContactShortly')}`,
    title: `${t('enquiryCall')}`,
    iconType: CONFIRM_MODAL.enquiry,
    isCloseIcon: true,
    isActionBtns: false
  };
  const handleButtonClick = async () => {
    try {
      const response: any = await API_SERVICES.generalService.createEnquiryCall(
        {
          orderTypeId: 1,
          ordererId: userDetails?.customer_id,
          failureMessage: 'Something went wrong!. Please try again later '
        }
      );
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        setConfirmModal({ open: true, onCancelClick, ...confirmModalProps });
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const renderTabContent = (tabVal?: any) => {
    const findActiveTab = tabItems.find(({ id }) => id === tabVal);
    return <Grid>{findActiveTab ? findActiveTab.component() : null}</Grid>;
  };

  return (
    <Grid
      className={classes.superOuterContainer}
      style={{
        backgroundImage:
          selectedTab === tabItems[0]?.id
            ? `url(${RoundPattern}), url(${DottedPattern})`
            : 'none',
        backgroundAttachment: 'fixed',
        backgroundPositionY: '-6rem, 29rem',
        backgroundPositionX: '80rem, right',
        backgroundRepeat: ' no-repeat, no-repeat'
      }}
    >
      <Grid container>
        <Grid item container xs={12} className={classes.addressContainer}>
          <Grid item xs>
            <AddressDisplay />
          </Grid>
          <Grid item>
            <EnquiryComp handleButtonClick={handleButtonClick} />
          </Grid>
        </Grid>
        <Grid item xs={selectedTab === tabItems[0]?.id ? 8 : 12}>
          <UHTabComponent
            currentTabVal={selectedTab}
            tabContent={tabItems}
            orientation={ORIENTATION.HORIZONTAL}
            tabIndicatorColor={theme.Colors.secondary}
            tabContainerClassName={classes.tabContainer}
            renderTabContent={renderTabContent}
            onTabChange={onTabChange}
            tabClasses={{
              root: classes.tabRoot,
              selected: classes.selectedTab
            }}
          />
        </Grid>
        {selectedTab === tabItems[0]?.id && (
          <Grid item xs={4} className={classes.imageContainer}>
            <img src={BookYourPageImage} alt="Image" />
          </Grid>
        )}
      </Grid>
      {confirmModal.open && <UHConfirmModal {...confirmModal} />}
    </Grid>
  );
}

export default HomePage;

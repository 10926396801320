import { useTheme, Grid } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  ButtonComp,
  LoginHeaderComp,
  TextInputComponent
} from 'src/components';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionComp } from './TermsAndConditionComp';
import { useEdit } from 'src/hooks/useEdit';
import { isValidEmail, isValidPinCode, capitalizeFirstLetter } from 'src/Utils';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5)
  }
}));

const CreateAccountSignUp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigateTo = useNavigate();
  const [isError, setIsError] = useState(false);
  const { state }: any = useLocation();

  const initialValues = {
    user_type_id: 0,
    first_name: '',
    last_name: '',
    email: '',
    image_url: '',
    location: '',
    pincode: '',
    map_url: '',
    full_address: ''
  };
  const edit = useEdit(initialValues);
  const RequiredFields = ['first_name', 'last_name', 'pincode', 'location'];

  const handleContinueClick = () => {
    if (
      !edit.allFilled(...RequiredFields) ||
      !isValidEmail(edit.getValue('email')) ||
      !isValidPinCode(edit.getValue('pincode'))
    ) {
      setIsError(true);
      return;
    }
    navigateTo('/customer/choose-customer-location', {
      state: {
        formEdits: { ...initialValues, ...edit.edits },
        customerId: state?.customerId
      }
    });
  };

  return (
    <Grid>
      <LoginHeaderComp
        title={t('LOGIN.signUp')}
        subText={t('LOGIN.userProfile')}
      />
      <Grid className={classes.container}>
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.firstName')}
          value={edit.getValue('first_name')}
          onChange={(e) => edit.update({ first_name: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            !edit.allFilled('first_name') &&
            'Please Enter your first name'
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.lastName')}
          value={edit.getValue('last_name')}
          onChange={(e) => edit.update({ last_name: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            !edit.allFilled('last_name') &&
            'Please Enter your last name'
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.email')}
          value={edit.getValue('email')}
          onChange={(e) => edit.update({ email: e.target.value })}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            (!edit.allFilled('email') ||
              (!isValidEmail(edit.getValue('email')) &&
                'Please Enter your valid email address'))
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.location')}
          value={edit.getValue('location')}
          onChange={(e) =>
            edit.update({ location: capitalizeFirstLetter(e.target.value) })
          }
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          helperText={
            isError &&
            !edit.allFilled('location') &&
            'Please enter your location'
          }
        />
        <TextInputComponent
          inputHeight={66}
          placeholderText={t('LOGIN.pinCode')}
          value={edit.getValue('pincode')}
          inputBorderRadius={0}
          textColor={theme.Colors.primary}
          onChange={(e) => {
            if (isNaN(Number(e.target.value))) {
              return;
            }
            edit.update({ pincode: e.target.value });
          }}
          helperText={
            ((isError && !edit.getValue('pincode')) ||
              (isError &&
                edit.getValue('pincode') &&
                !isValidPinCode(edit.getValue('pincode')))) &&
            'Please enter your valid pincode'
          }
        />
        <ButtonComp
          buttonText={t('LOGIN.continue')}
          backgroundColor={theme.Colors.secondary}
          btnBorderRadius={theme.MetricsSizes.tiny}
          onClickButton={handleContinueClick}
          style={{ margin: theme.spacing(2, 0) }}
        />
        <TermsAndConditionComp />
      </Grid>
    </Grid>
  );
};

export default CreateAccountSignUp;

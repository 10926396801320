import { CssBaseline } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import ThemeProvider from './theme/ThemeProvider';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';
enum AUTH_STATE {
  NOT_LOGGED_ID,
  CHECKING,
  SIGNED_IN
}

function App() {
  const content = useRoutes(router);
  useEffect(() => {
    OneSignal.init({
      appId: '3ca1bd0f-2c85-4142-8ef0-7a621a0f4a2d'
    });
    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) {
        OneSignal.getUserId(function (userId) {
          localStorage.setItem('playerId', userId);
        });
      }
    });
  }, []);
  return (
    <ThemeProvider>
      <CssBaseline />
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            padding: '10px',
            color: '#FFFF',
            fontSize: 15
          },
          success: {
            style: {
              background: 'green'
            },
            duration: 2000
          },
          error: {
            style: {
              background: 'red'
            },
            duration: 2000
          }
        }}
      />
      {content}
    </ThemeProvider>
  );
}
export default App;

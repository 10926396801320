import React from 'react';

import {
  contactPic,
  contact,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  location,
  phoneicon,
  mailicon
} from 'src/Assets';
import './website.css';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { ButtonComp } from 'src/components';
function ContactUs() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };

  return (
    <>
      <img className="contact-pic" src={contactPic} alt="" />
      <section>
        <div className="contact-page1">
          <div className="side-head">
            <a onClick={navigationToHome}>{t('WEBCONTACT.home')}</a> |{' '}
            <a className="current-view">{t('WEBCONTACT.contactUs')}</a>
          </div>
          <div>
            <h1 className="contact-title">{t('WEBCONTACT.contactUs')}</h1>
            <p className="contact-subtitle">{t('WEBCONTACT.letsStart')}</p>
          </div>
          <div className="contact-container">
            <form action="#">
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.fullName')}</span>
                <input type="text" placeholder="" />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.emailAddress')}</span>
                <input type="text" placeholder="" />
              </div>

              <div className="input-box">
                <span className="details">{t('WEBCONTACT.phoneNumber')}</span>
                <input type="text" placeholder="" />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.companyName')}</span>
                <input type="text" placeholder="" />
              </div>

              <div className="input-box msg">
                <span className="details">{t('WEBCONTACT.message')}</span>
                <input type="text" placeholder="" />
                <input type="text" />
              </div>

              <div className="button">
                <ButtonComp
                  buttonText={t('WEBCONTACT.send')}
                  btnWidth={167}
                  height={55}
                  // btnBorderRadius='50px'
                  style={{
                    marginBottom: '5rem'
                  }}
                  btnBorderRadius={50}
                  backgroundColor="#6cb044"
                />
                {/* <input type="submit" value="SEND" /> */}
              </div>
            </form>
          </div>
        </div>
        <div className="contact-page2">
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d25239.239330498367!2d79.88719048059392!3d12.931045901004381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1663585002119!5m2!1sen!2sin"
            width="100%"
            height="600"
            style={{ border: 0 }}
            loading="lazy"
            // referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="contact-map">
            <p
              style={{
                fontFamily: 'Space Grotesk',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '74px',
                marginTop: '-10px'
              }}
            >
              {t('WEBCONTACT.ourLocations')}
              {/* Our Locations */}
            </p>
            <div className="location-map">
              <img
                src={location}
                style={{ width: '65px', height: '65px' }}
                alt=""
              />
              <span className="location-text">{t('Contact.location')}</span>
            </div>
            <div className="phone-map">
              <img src={phoneicon} className="phone-image" alt="" />
              <span className="phone-text">
                (+91) 89399 09555, (+91) 89399 76555
              </span>
            </div>
            <div className="location-map">
              <img src={mailicon} className="phone-image" alt="" />
              <span className="phone-text">support@dustmanrecycling.com</span>
            </div>
          </div>
        </div>
        <div className="landing5">
          <div className="client-title">
            <p>{t('WEBCONTACT.ourClients')}</p>
            <h3>
              {t('WEBCONTACT.ourClients')}
              <br></br>
              {t('WEBCONTACT.workFor')}
            </h3>
          </div>
          <div className="client-list">
            <div className="client">
              <img src={client1} alt="" />
            </div>
            <div className="client">
              <img src={client2} alt="" />
            </div>
            <div className="client">
              <img src={client3} alt="" />
            </div>
            <div className="client">
              <img src={client4} alt="" />
            </div>
            <div className="client">
              <img src={client5} alt="" />
            </div>
            <div className="client">
              <img src={client6} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactUs;

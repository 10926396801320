import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/dustmanUI/Services';

const TermsContent = () => {
  const [content, setContent] = useState<any>();
    const handleTermsClick = async () => {
      const response: any =
        await API_SERVICES.generalService.getTermsAndCondition();
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        setContent(response?.data);
      }
      console.log('response', response);
    };
    useEffect(() => {
      handleTermsClick();
    }, []);
  return <div style={{margin:"20px"}}dangerouslySetInnerHTML={{ __html: content }}></div>;
};
export default TermsContent;

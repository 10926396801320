import React, { useEffect, useState } from 'react';
import { API_SERVICES } from 'src/dustmanUI/Services';
import { HTTP_STATUSES } from 'src/Config/constant';
import { SelectVehicleType } from 'src/components';
import { useTheme } from '@material-ui/core';



function SelectVehicle({ edit }) {
  const [dataContent, setDataContent] = useState([]);
  const theme = useTheme();

  const fetchData = async () => {
    const response: any = await API_SERVICES.generalService.getAllVehicles();
    if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
      if (response?.data?.vehicles) {
        setDataContent(response.data.vehicles);
      }
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      setDataContent([]);
    };
  }, []);

  const handleClick = (vehicleId: number) => {
    edit.update({
      customer_order_details: {
        ...edit.edits.customer_order_details,
        vehicle_id: vehicleId
      }
    });
  };

  return (
    <SelectVehicleType
     color={theme.Colors.secondary}
     isCustomer={true}
      dataContent={dataContent}
      selectedVal={edit.getValue('customer_order_details').vehicle_id}
      onClick={handleClick}
    />
  );
}

export default SelectVehicle;

import React, { useEffect, useState } from 'react';
import { HTTP_STATUSES } from 'src/Config/constant';
import { API_SERVICES } from 'src/dustmanUI/Services';

const PrivacyContent=()=>{
    const[privacy,setPrivacy]=useState<any>();
       const handlePrivacyClick = async () => {
      const response: any =
        await API_SERVICES.generalService.getPrivacyPolicy();
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        setPrivacy(response?.data);
      }
      console.log('setPrivacy', privacy);
    };
    useEffect(() => {
      handlePrivacyClick();
    }, []);
    return (
      <>
        <div
          style={{ margin: '20px' }}
          dangerouslySetInnerHTML={{ __html: privacy }}
        ></div>
      </>
    );
}
export default PrivacyContent;